<template>
  <div class="labeled-switch">
    <span :class="{ checked: !checked }">{{ $t("no") }}</span>
    <b-form-checkbox
      :checked="checked"
      @input="$emit('input', $event)"
      switch
    />
    <span :class="{ checked }">{{ $t("yes") }}</span>
  </div>
</template>

<script>
export default {
  name: "labeled-switch",
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.labeled-switch {
  display: flex;
  margin-top: 8px;

  span {
    margin-right: 10px;

    &.checked {
      color: $primary;
    }
  }
}
</style>
